import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeSharpIcon from '@mui/icons-material/RemoveRedEyeSharp';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ReactSrcDocIframe from 'react-srcdoc-iframe';
import "./styles.css"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const QuesComponent = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  const handleclick = () => {
    navigate('/dashboard-exam', {
      state: {
        link: props.linkquestion,
        quesid: props.pid,
        isCodeDes: props.isCode,
      },
    });
  };
  const paperStyle = {
    padding: '5px 20px',
    height: '10vh',
    width: '90vw',
    margin: '28px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  
  const html = `${props.linkquestion}`;

  return (
    <>
      <Paper elevation={7} style={paperStyle}>
        <div style={{ userSelect: 'none', width: '75%' }}>
          <h3 style={{ margin: 0 }}>
            {props.idx + 1}. {props.questionname}{' '}
            <Button onClick={handleClickOpen}>
              <RemoveRedEyeSharpIcon />
            </Button>
          </h3>
        </div>
        {
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              Question {props.idx + 1}
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                {/* <iframe src={props.linkquestion} sandbox="allow-same-origin allow-scripts" width="580" height="550" allow="autoplay"></iframe> */}
                <ReactSrcDocIframe srcDoc={html} height="550" width="100%" />
              </Typography>
            </DialogContent>
          </BootstrapDialog>
        }
        {props.isCode && (
          <div style={{ color: '#1976d2' }}>
            {/* <p style={{ margin: 0 }}>25 Min</p> */}
          </div>
        )}
        <div>
          <p style={{ margin: 0 }}>{props.marks}</p>
        </div>
        <div>
          {!props.isSubmit ? (
            <Button variant="contained" onClick={handleclick}>
              Start
            </Button>
          ) : (
            <Button variant="contained" disabled>
              Submitted
            </Button>
          )}
        </div>
      </Paper>
    </>
  );
};

export default QuesComponent;
